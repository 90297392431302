// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.re.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.re.mjs";
import * as ReactGoogleMaps from "@vis.gl/react-google-maps";
import * as PinMap_eventConnection_graphql from "../../__generated__/PinMap_eventConnection_graphql.re.mjs";

var convertFragment = PinMap_eventConnection_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(PinMap_eventConnection_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PinMap_eventConnection_graphql.node, convertFragment);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function PinMap(props) {
  var selected = props.selected;
  var onLocationClick = props.onLocationClick;
  var connection = use(props.connection);
  var locations = Core__Option.getOr(connection.edges, []).map(function (__x) {
        return Core__Option.flatMap(__x, (function (edge) {
                      return Core__Option.flatMap(edge.node, (function ($$event) {
                                    return Core__Option.map($$event.location, (function ($$location) {
                                                  return [
                                                          $$event.id,
                                                          $$location
                                                        ];
                                                }));
                                  }));
                    }));
      });
  return JsxRuntime.jsx(ReactGoogleMaps.APIProvider, {
              apiKey: "AIzaSyBFLsnHmBaptaYoFhkXI6uL6peX579N5UY",
              children: JsxRuntime.jsx(ReactGoogleMaps.Map, {
                    mapId: "eventsListMap",
                    defaultZoom: 12,
                    defaultCenter: {
                      lat: 35.6495,
                      lng: 139.7417
                    },
                    children: Caml_option.some(locations.map(function ($$location, i) {
                              return Core__Option.getOr(Core__Option.flatMap($$location, (function (param) {
                                                var $$location = param[1];
                                                return Core__Option.map($$location.coords, (function (coords) {
                                                              return JsxRuntime.jsx(ReactGoogleMaps.AdvancedMarker, {
                                                                          position: coords,
                                                                          children: Caml_option.some(Core__Option.getOr(Core__Option.map(selected, (function (s) {
                                                                                          return s === $$location.id;
                                                                                        })), false) ? JsxRuntime.jsx(ReactGoogleMaps.Pin, {
                                                                                      background: "#0f9d58",
                                                                                      glyphColor: "#60d98f",
                                                                                      borderColor: "#006425",
                                                                                      scale: 1.5
                                                                                    }) : JsxRuntime.jsx(ReactGoogleMaps.Pin, {})),
                                                                          onClick: (function (param) {
                                                                              onLocationClick($$location);
                                                                            })
                                                                        }, coords.lat.toString() + "|" + coords.lng.toString() + i.toString());
                                                            }));
                                              })), null);
                            }))
                  })
            });
}

var make = PinMap;

export {
  Fragment ,
  make ,
}
/* react/jsx-runtime Not a pure module */
